import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import Spinner from 'react-bootstrap/Spinner'
import { pageRoutes } from 'constants/routing'
import useThriveAuth from 'hooks/useThriveAuth'
import { USER_TYPE_KEY } from 'constants/api'
import Cookies from 'js-cookie'
import { COOKIE_KEYS, UserTypes } from 'constants/common'
import { isFirstTimeUser } from 'utils/common'
import { getTreatment } from 'utils/treatment'
import { TreatmentFlags, treatmentFlagValues } from 'constants/treatments'

const AppHome: React.FC = () => {
  const router = useRouter()
  const {
    isLoading,
    isAuthenticated,
    isSupportUser,
    user,
    isUserLoading,
    isClinicalAdmin,
    isDevOpsAdmin,
    isSoftwareDevs,
  } = useThriveAuth()

  const isAMDUser = user?.[USER_TYPE_KEY] === UserTypes.AMD
  const firstTimeUser = isFirstTimeUser(user as User)
  const hasAccessedFirstTimeFLow = useMemo(
    () => Cookies.get(COOKIE_KEYS.VISITED_FIRST_TIME_FLOW) === 'true',
    []
  )

  useEffect(() => {
    if (!isLoading && !isUserLoading) {
      let redirectUrl = pageRoutes.PROVIDER_SEARCH
      const isBookingDisabled =
        getTreatment(TreatmentFlags.THRIVEWEB_BOOKINGS) ===
        treatmentFlagValues.DISABLE

      if (isAuthenticated && isSupportUser != null) {
        if (isAMDUser && firstTimeUser && !hasAccessedFirstTimeFLow) {
          redirectUrl = pageRoutes.VERIFY_SUCCESS
        } else if (!isBookingDisabled) {
          /*
            Redirect to dashboard only if bookings are enabled.
            Otherwise if THRIVE_BOOKINGS flag is disabled then
            redirect to search page
          */

          if (isClinicalAdmin || isDevOpsAdmin || isSoftwareDevs) {
            redirectUrl = pageRoutes.LOGIN_DASHBOARD
          } else if (isSupportUser) {
            redirectUrl = pageRoutes.DASHBOARD_SUPPORT
          } else {
            redirectUrl = pageRoutes.DASHBOARD_USER_HOME
          }
        }

        router.replace(redirectUrl)
      } else if (!isAuthenticated) {
        router.replace(redirectUrl)
      }
    }
  }, [isUserLoading, isLoading, isSupportUser, isAuthenticated])

  return (
    <div className="h-100 flex-center">
      <Spinner animation="border" />
    </div>
  )
}

export default AppHome
